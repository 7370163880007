<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'MemberCardOnline', 'create' ])">添加</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>

    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>

export default {
  data() {
    return {
      searches: {
        title: "标题"
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "标题",
          key: "title",
          sortable: "custom",
          align: "center",
          minWidth: 150
        },
        {
          title: "有效天数",
          key: "valid_days",
          minWidth: 120,
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.valid_days + "天");
          }
        },
        {
          title: "市场价",
          minWidth: 120,
          key: "market_price",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.market_price
            );
          }
        },
        {
          title: "售价",
          minWidth: 120,
          key: "price",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.price
            );
          }
        },
        
        {
          title: "分享赚优惠价",
          minWidth: 150,
          key: "share_price",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.share_price
            );
          }
        },
        {
          title: "分享佣金",
          minWidth: 120,
          key: "share_commission",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.share_commission
            );
          }
        },
        {
          title: "链接有效天数",
          key: "share_valid_days",
          minWidth: 150,
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.share_valid_days + "天");
          }
        },
        {
          title: "已购买数量",
          key: "records_count",
          minWidth: 120,
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.records_count);
          }
        },
        {
          title: "状态",
          minWidth: 90,
          key: "status",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: params.row.status
                  ? "ios-checkmark-circle"
                  : "ios-remove-circle"
              },
              class: params.row.status ? "text-green" : "text-red"
            });
          }
        },
        {
          title: "添加时间",
          minWidth: 180,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["MemberCardOnline", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            if (this.Auth(["MemberCardOnline", "delete"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "error",
                    size: "small",
                    icon: "md-trash"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.remove(params.row);
                    }
                  }
                })
              );
            }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/memberCardOnline/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
   
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },

    create() {
      this.$router.push("/member-card-online/create");
    },
    update(item) {
      this.$router.push("/member-card-online/update/" + item.id);
    },
   
    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该会员卡吗？",
        content: item.name,
        onOk: () => {
          this.$http.post("/memberCardOnline/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
    
  }
};
</script>